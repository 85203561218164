// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
const ScrollReveal = require('scrollreveal')
require('paroller.js')


$(() => {

    ScrollReveal().reveal('[animate]', {
        distance: '30px',
        opacity: 0,
        duration: 1000,
        interval: 200
    })
    ScrollReveal().reveal('[animate-right]', {
        distance: '30px',
        opacity: 0,
        duration: 2000,
        interval: 200,
        origin: "left"
    })
    ScrollReveal().reveal('[animate-left]', {
        distance: '30px',
        opacity: 0,
        duration: 2000,
        interval: 200,
        origin: "right"
    })
    ScrollReveal().reveal('[animate-scale]', {
        distance: '0px',
        opacity: 0,
        scale: .6,
        duration: 1000,
        interval: 200,
        origin: "right"
    })
    ScrollReveal().reveal('[animate-scale2]', {
        distance: '0px',
        opacity: 0,
        scale: .6,
        duration: 1000,
        interval: 200,
        origin: "right"
    })
    ScrollReveal().reveal('.crop-animate', {
        distance: '0px',
        opacity: 1,
        scale: 1,
        duration: 0,
        afterReveal(el) {
            el.classList.add('active')
        }
    })

    ScrollReveal().reveal('.num-animate', {
        distance: '0px',
        opacity: 1,
        scale: 1,
        duration: 0,
        afterReveal(el) {
            let number = 0
            let target = $(el).attr('data-num')
            let interval = setInterval(function() {
                $(el).text(number);
                if (number >= target) clearInterval(interval);
                number++;
            }, 25);
        }
    })


    ScrollReveal().reveal('.control-card', {
        distance: '20px',
        opacity: 0,
        scale: 1,
        duration: 1000,
        interval: 200,
        origin: "top"
    })


    $(".bg-element").paroller({
        factor: 0.3, // multiplier for scrolling speed and offset
        factorXs: 0.1, // multiplier for scrolling speed and offset
        type: 'foreground', // background, foreground
        direction: 'vertical', // vertical, horizontal
        transition: 'transform 2s ease' // CSS transition
    })

    var curentPopup

    $('.popup-close').on('click', () => {
        curentPopup.removeClass('active')
        curentPopup.fadeOut('600')
        $('.video-container iframe').attr('src', '')
        curentPopup = undefined
    })

    $('[data-video]').on('click', function() {
        curentPopup = $('#video-popup')
        $('.video-container iframe').attr('src', $(this).attr('data-video'))
        curentPopup.fadeIn('800', () => {
            curentPopup.addClass('active')
        })
    })
    $('[data-call-consultation]').on('click', function() {
        curentPopup = $('#consultation')
        curentPopup.fadeIn('800', () => {
            curentPopup.addClass('active')
        })
    })

    // wp form checkbox fix
    $('.wpcf7-list-item-label').remove()

    $('.wpcf7-submit').on('click', () => {
        let checkbox = $('[name="agreement[]"]')
        let label = $('label.checkbox')

        if (!$(checkbox[0]).prop('checked')) {
            label.addClass('warning')
            checkbox.one('change', () => {
                label.removeClass('warning')
            })
        }
    })

    $(() => {
        $('.loader').addClass('hide')
    })

});